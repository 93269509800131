<script setup lang="ts">
import Container from '../layout/Container.vue'
import { useBox } from '~/composables/api/useBox'

const clientsLogosBoxResponse = await useAsyncData(() =>
  useBox('index_clients')
)

const clientLogoItems = computed(() => {
  return clientsLogosBoxResponse.data.value?.box_items.map((item) => ({
    id: item.id,

    image: getImageSrcSizesData(item.image, {
      defaultFormat: '200x400',
      sizes: '(max-width: 575.99px) 100px, 200px',
    }),
    name: item.name,
  }))
})
</script>

<template>
  <div class="c-clients-logos">
    <Container class="c-clients-logos__container">
      <h2 class="c-clients-logos__title">
        {{ getTextConfiguration('index.clients.title') }}
      </h2>

      <div class="c-clients-logos__logos-holder">
        <div v-if="clientsLogosBoxResponse.pending.value" class="sc-logos">
          <div v-for="i in 6" :key="i" class="sc-logo sc-skeleton" />
        </div>

        <div v-else class="sc-logos">
          <div
            v-for="clientLogoItem in clientLogoItems"
            :key="clientLogoItem.id"
            class="sc-logo"
          >
            <img
              :src="clientLogoItem.image.src"
              :sizes="clientLogoItem.image.sizes"
              :srcset="clientLogoItem.image.srcset"
              :alt="clientLogoItem.name"
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <div class="c-clients-logos__other">
        {{ getTextConfiguration('index.clients.additionalText') }}
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@use '@inkline/inkline/css/mixins' as *;
@use '@/assets/styles/mixins/skeleton' as skeleton;

.c-clients-logos {
  margin-bottom: 110px;
  overflow: hidden;

  @include breakpoint-down('lg') {
    margin-bottom: 80px;
  }

  @include breakpoint-down('md') {
    margin-bottom: 80px;
  }

  &__container {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 360px;

    @include breakpoint-down('xl') {
      grid-template-columns: auto 1fr 280px;
    }
    @include breakpoint-down('lg') {
      grid-template-columns: auto 1fr 200px;
    }
    @include breakpoint-down('md') {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 0.96;
    margin: 0;
    align-self: end;
    grid-column: 1 / -1;
    margin-bottom: 48px;

    @include breakpoint-down('xs') {
      font-size: 32px;
      text-align: center;
      margin-bottom: 32px;
    }
  }

  &__logos-holder {
    display: grid;
    grid-template-columns: subgrid;
    grid-column: 1 / 3;
    grid-row: 2;
    border-top: 1px solid var(--color-border);
    border-bottom: 1px solid var(--color-border);

    .sc-logos {
      display: grid;
      place-items: center;
      grid-template-columns: repeat(3, 1fr);
      grid-column: 1 / 2;
      padding: 74px 60px 74px 110px;
      gap: 40px 50px;
      min-width: 0;

      @include breakpoint-down('xl') {
        padding: 40px 70px;
        gap: 24px 40px;
      }
      @include breakpoint-down('lg') {
        padding: 32px 60px;
      }
      @include breakpoint-down('md') {
        padding: 35px 20px;
      }

      .sc-skeleton {
        @include skeleton.skeleton;
        width: 200px;
        height: 80px;
        border-radius: 2px;

        @include breakpoint-down('md') {
          width: stretch;
        }
      }

      .sc-logo {
        min-width: 0;
        max-width: 200px;
        max-height: 80px;

        @include breakpoint-down('xl') {
          max-width: 160px;
          max-height: 60px;
        }
        @include breakpoint-down('lg') {
          max-width: 140px;
          max-height: 50px;
        }
        @include breakpoint-down('md') {
          max-width: 120px;
          max-height: 40px;
        }
        @include breakpoint-down('sm') {
          max-width: 110px;
          max-height: 30px;
        }
      }

      img {
        width: 100%;
        height: 100%;
        max-width: inherit;
        max-height: inherit;
        object-fit: contain;
      }
    }
  }

  &__other {
    grid-row: 2;
    grid-column: 2 / 4;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-family: var(--font-family-secondary-base);
    font-size: 58px;
    line-height: 96%;

    @include breakpoint-down('xl') {
      font-size: 40px;
    }
    @include breakpoint-down('md') {
      display: none;
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: var(--color-secondary);
      margin-top: 24px;
    }
  }
}
</style>
